<template>
  <div id="app">
    <router-view v-if="isRouterAlive" />
  </div>
</template>

<script>
import store from '@/store'
import { mapMutations } from 'vuex'
import { platform } from '@/api/config'

// const token1 =
//   'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpc3MiOiJodHRwOi8vZGVhcGkuY3psa3MuY29tIiwiYXVkIjoiaHR0cDovL2RlYXBpLmN6bGtzLmNvbS92MS9sb2dpbiIsImp0aSI6InR6a2oiLCJpYXQiOjE2OTU3MjU3ODIuNDQwMDEyLCJleHAiOjE2OTU3MjkzODIuNDQwMDEyLCJ1aWQiOiIxNjk1NzI1NzY0MDAwNDcyIn0.9ETNV2QJxfG9ve7XVjtgvwj-UDqTSDKuxFEUAy6c0XQ'
// if (token1) {
//   store.dispatch('getUserInfo')
//   store.commit('SET_TOKEN', token1)
//   sessionStorage.setItem('token', token1)
// }

const token = sessionStorage.getItem('token')
if (token) {
  store.commit('SET_TOKEN', token)
  store.dispatch('getUserInfo')
}

export default {
  provide() {
    return {
      reload: this.reload
    }
  },
  data() {
    return {
      isRouterAlive: true
    }
  },
  created() {
    this.changeIco()
  },
  methods: {
    ...mapMutations({
      setRouterPath: 'SET_ROUTER_PATH'
    }),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(function () {
        this.isRouterAlive = true
      })
    },
    // 修改Favicon的方法
    changeFavicon(link) {
      let $favicon = document.querySelector('link[rel="icon"]')
      if ($favicon !== null) {
        $favicon.href = link
      } else {
        $favicon = document.createElement("link")
        $favicon.rel = 'icon'
        $favicon.href = link
        document.head.appendChild($favicon)
      }
    },
    // 根据传递的参数修改Favicon
    changeIco() {
      let iconUrl
      if (platform === 'msyz') {
        iconUrl = `./msyz_favicon.ico`
      } else if (platform === 'digitalEmployment') {
        iconUrl = `./szzy_favicon.ico`
      }
      this.changeFavicon(iconUrl)
    }
  },
  watch: {
    $route(to) {
      this.setRouterPath(to.path)
    }
  }
}
</script>

<style lang="stylus" scoped></style>
